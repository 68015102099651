<template>
  <div id="app">
    <van-nav-bar title="工单详情" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-tabs v-model="active" @click="clickTabs">
      <van-tab title="详情">
        <div class="order-detail">
          <van-form>
            <van-collapse v-model="activeName" accordion>
              <van-collapse-item :title="eqName" :name="1">
                <div v-for="(item, index) in eqList" :key="index" class="innerBox">
                  <div style="overflow: hidden">
                    <div style="overflow: hidden">
                      <span class="fl font-black">部位：<span v-if="item.part">{{ item.part }}</span><span v-else>-</span></span>
                      <span class="fr">
                        <van-icon name="photo" size="14" @click="takePhoto(item.id, index, item.eq.id)" />
                      </span>
                    </div>
                    <p hidden class="font-black">方法：{{ item.task.name }}</p>
                    <p class="font-black">内容：{{ item.param }}</p>
                    <p class="font-black">要求：{{ item.param_detail }}</p>
                  </div>
                  <img :src="item.photo" alt="" v-if="item.photo" width="100" height="100">
                </div>
              </van-collapse-item>
            </van-collapse>
          </van-form>
          <div v-if="[1, 100].includes(data.status) && eqList.length === 1" style="margin: 16px;">
            <van-button round block type="primary" native-type="button" @click="submitReading">提交</van-button>
          </div>
        </div>
      </van-tab>
      <van-tab title="人员">
        <van-notice-bar left-icon="volume-o" scrollable :text="noticeOption.text" />
        <van-pull-refresh v-model="detailLoading" @refresh="onDetailRefresh">
          <van-cell-group>
            <div class="van-cell">
              <span>执行人</span>
            </div>
            <van-swipe-cell v-for="(item, index) in data.other_member" :key="index" >
              <van-cell>
                <span v-if="item.admin">{{ item.admin.name }}</span>
                <span v-else>该账号已被删除</span>
              </van-cell>
              <template v-if="![2, 200].includes(data.status)" #right>
                <van-button square type="danger" text="删除" @click="removeMemberHandle(item.id)" />
              </template>
            </van-swipe-cell>
          </van-cell-group>
          <div v-if="![2, 200].includes(data.status)" class="order-detail">
            <div class="cell">
              <van-field v-model="admins.adminShow" label="执行者" placeholder="请选择执行者" @click="showAdminPicker" right-icon="arrow-down" />
            </div>
            <van-popup v-model:show="showAdmin" position="bottom">
              <van-picker show-toolbar :columns="adminList" @confirm="hideAdmin" @cancel="showAdmin = false" value-key="name" :loading="loadingAdminPicker">
                <template #title>
                  <van-search v-model="adminQuery.name" @search="searchAdmin" placeholder="请输入搜索关键词" />
                </template>
              </van-picker>
            </van-popup>
            <div class="buttonBox">
              <van-button round block color="#1890ff" @click="appendMemberHandle">添加执行人</van-button>
            </div>
          </div>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="时间">
        <van-notice-bar left-icon="volume-o" scrollable :text="noticeOption.text" />
        <van-pull-refresh v-model="detailLoading" @refresh="onDetailRefresh">
          <van-cell-group>
            <div class="pmTimeTitle van-cell">
              <span class="fl">开始时间</span>
              <span class="fr">结束时间</span>
            </div>
            <van-swipe-cell v-for="(item, index) in data.other_time" :key="index">
              <van-cell>
                <span>
                    <div class="fl">{{ item.start_time }}</div>
                    <div class="fr">{{ item.end_time }}</div>
                  </span>
              </van-cell>
              <template v-if="[1, 100].includes(data.status)" #right>
                <van-button square type="danger" text="删除" @click="removeTimeHandle(item.id)" />
              </template>
            </van-swipe-cell>
          </van-cell-group>
          <div v-if="[1, 100].includes(data.status)" class="order-detail">
            <div class="cell">
              <van-field v-model="showStartTime" label="开始时间" placeholder="选择开始时间" @click="showStart = true" right-icon="arrow-down" />
            </div>
            <div class="cell">
              <van-field v-model="showEndTime" label="完成时间" placeholder="选择完成时间" @click="showComplete = true" right-icon="arrow-down" />
            </div>
            <van-popup v-model:show="showStart" position="bottom">
              <van-datetime-picker v-model="orderTime.start" type="datetime" title="选择开始时间" :min-date="minDate" @confirm="hideStart" @cancel="showStart = false" />
            </van-popup>
            <van-popup v-model:show="showComplete" position="bottom">
              <van-datetime-picker v-model="orderTime.end" type="datetime" title="选择完成时间" :min-date="minDate" @confirm="hideComplete" @cancel="showComplete = false" />
            </van-popup>
            <div class="buttonBox">
              <van-button round block color="#1890ff" @click="appendTimeHandle">添加时间段</van-button>
            </div>
          </div>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="备件">
        <van-notice-bar left-icon="volume-o" scrollable :text="noticeOption.text" />
        <van-pull-refresh v-model="detailLoading" @refresh="onDetailRefresh">
          <div class="pmPartsList">
            <van-cell-group>
              <van-cell title="备件名称" value="数量单位" label="型号" title-class="tl" />
              <van-swipe-cell v-for="(item, index) in data.other_parts" :key="index">
                <van-cell v-if="item.already === 3">
                  <span v-if="item.parts">
                    <div class="fl" style="color: green">{{ item.parts.name }}-{{ item.parts.spec }}</div>
                    <div class="fr" style="color: green">{{ item.number }}</div>
                  </span>
                  <span v-else>
                    <div class="fl" style="color: green">备件已删除</div>
                    <div class="fr" style="color: green">{{ item.number }}</div>
                  </span>
                </van-cell>
                <van-cell v-else-if="item.already === 2">
                  <span v-if="item.parts">
                    <div class="fl" style="color: blue">{{ item.parts.name }}-{{ item.parts.spec }}</div>
                    <div class="fr" style="color: blue">{{ item.number }}</div>
                  </span>
                  <span v-else>
                    <div class="fl" style="color: blue">备件已删除</div>
                    <div class="fr" style="color: blue">{{ item.number }}</div>
                  </span>
                </van-cell>
                <van-cell v-else>
                  <span v-if="item.parts">
                    <div class="fl">{{ item.parts.name }}<br>{{ item.parts.spec }}</div>
                    <div class="fr">{{ item.number }}</div>
                  </span>
                  <span v-else>
                    <div class="fl">备件已删除</div>
                    <div class="fr">{{ item.number }}</div>
                  </span>
                </van-cell>
                <template v-if="[-1, 1, 100].includes(data.status)" #right>
                  <van-button square type="danger" text="删除" @click="removePartsHandle(item.id, item.already)" />
                </template>
              </van-swipe-cell>
            </van-cell-group>
          </div>
          <div v-if="[-1, 1, 100].includes(data.status)" class="order-detail">
            <div class="cell">
              <van-field v-model="order.partsShow" label="备件" placeholder="请选择备件" @click="showPartsPicker" right-icon="arrow-down" />
            </div>
            <van-popup v-model:show="showParts" position="bottom">
              <van-picker show-toolbar :columns="partsList" @confirm="hideParts" @cancel="showParts = false" value-key="name" :loading="loadingPicker">
                <template #title>
                  <van-search v-model="partsQuery.name" @search="searchParts" placeholder="请输入搜索关键词" />
                </template>
              </van-picker>
            </van-popup>
            <div class="fz-16">
              <p class="pl-10">当前数量：{{ applyParts.number }}</p>
              <p class="pl-10">备件编码：{{ applyParts.coding }}</p>
              <p class="pl-10">所在库房：{{ applyParts.store }}</p>
              <p class="pl-10">备件型号：{{ applyParts.spec }}</p>
            </div>
            <van-field v-model="needPartsNumber" type="digit" label="需要数量" />
            <div class="buttonBox">
              <van-button round block color="#1890ff" @click="appendPartsHandle">添加备件</van-button>
            </div>
            <div class="buttonBox">
              <van-button round block type="success" @click="getParts">请求备件</van-button>
            </div>
          </div>
          <div class="bottomBox"></div>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="工单">
        <van-pull-refresh v-model="detailLoading" @refresh="onDetailRefresh">
          <van-loading color="#1989fa" class="page-loading" :hidden="pmLoadingHidden" />
          <div class="order-detail" :hidden="pmLoading">
            <van-field v-model="data.admin.name" label="负责人" readonly />
            <van-field v-model="data.eq.name" label="资产" readonly />
            <van-field v-model="data.eq.coding" label="资产编号" readonly />
            <van-field v-model="data.arrange_date" label="计划开始时间" readonly />
            <van-field v-if="data.status !== -1" v-model="data.end_time" label="计划结束时间" readonly />
            <!--
            <van-field v-if="data.status !== 1" v-model="showOrderEndTime" label="计划结束时间" placeholder="选择开始时间" @click="showEnd = true" right-icon="arrow-down" hidden />
            <van-popup v-model:show="showEnd" position="bottom">
              <van-datetime-picker v-model="orderTime.order_end" type="datetime" title="计划结束时间" :min-date="minDate" @confirm="hideOrderEnd" @cancel="showEnd = false" />
            </van-popup>
            -->
            <van-field v-if="data.submit_complete" v-model="data.submit_complete" label="完成时间" />
            <van-field v-model="statusOut" label="工单状态" />
            <van-field v-model="data.content" rows="2" autosize label="描述" type="textarea" maxlength="200" placeholder="描述" show-word-limit />
            <div style="margin: 16px;">
              <div class="buttonBox" v-if="[20, 2000].includes(data.status) && pmCanMonitor === 2">
                <van-button round block type="danger" class="mb20" @click="monitorHandle(2)">班长退回</van-button>
                <van-button round block type="success" class="mt-10" @click="monitorHandle(1)">班长审核</van-button>
              </div>
              <div class="buttonBox" v-if="[30, 3000].includes(data.status) && pmCanEngineer === 2">
                <van-button round block type="danger" class="mb20" @click="engineerHandle(2)">工程师退回</van-button>
                <van-button round block type="success" class="mt-10" @click="engineerHandle(1)">工程师审核</van-button>
              </div>
              <div class="buttonBox" @click="saveHandle">
                <van-button round block color="#1b89fa">保存</van-button>
              </div>
              <div v-if="([-1, 10].includes(data.status)) && pmCanHandOut === 2" class="buttonBox" @click="handOutHandle">
                <van-button round block color="#07c160">派发</van-button>
              </div>
            </div>
          </div>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import API from '@/api/workorder/pm'
import SELECT from '@/api/select'
import { planType, pmStatus } from '@/utils/options'
import {Dialog, Notify, Toast} from 'vant'
import WxApi from "@/api/wx"
import wx from "weixin-js-sdk"
import {mapGetters} from "vuex";
export default {
  inject: ['backUrl', 'reload', 'showSize', 'goUrl', 'isWorkorderAdmin', 'dateTimeFormat', 'parserDate', 'selectName'],
  data() {
    return{
      data: {
        admin: {
          name: ''
        }
      },
      eqList: [],
      parts: {
        data: []
      },
      eqName: '',
      eqId: 0,
      activeName: 1,
      active: 1,
      detailLoading: false,
      pmLoadingHidden: false,
      pmLoading: true,
      finishWx: false,
      isAdmin: false,
      needPartsNumber: null,
      order: {},
      applyParts: {},
      loadingPicker: false,
      partsPicker: '',
      showParts: false,
      partsQuery: {
        name: '',
        limit: 100
      },
      partsList: [],
      admins: {},
      applyAdmin: {},
      loadingAdminPicker: false,
      adminPicker: '',
      adminQuery: {
        name: '',
        limit: 100
      },
      adminList: [],
      showAdmin: false,
      showStart: false,
      showEnd: false,
      showComplete: false,
      showStartTime: '',
      showEndTime: '',
      showOrderEndTime: '',
      orderTime: {},
      minDate: new Date(2022, 1, 1),
      statusOut: '',
      noticeOption: {
        text: ''
      },
      planType,
      pmStatus
    }
  },
  computed: {
    ...mapGetters([
      'userId',
      'pmCanHandOut',
      'pmCanMonitor',
      'pmCanEngineer',
      'jName'
    ])
  },
  created() {
    this.getConfig()
    this.index()
    if (this.$route.query.eqId) {
      // this.activeName = Number(this.$route.query.eqId)
    }
  },
  methods: {
    index() {
      this.pmLoadingHidden = false
      this.pmLoading = true
      const id = this.$route.params.id
      API.getOne(id).then(response => {
        if (response.data.error_code) {
          Dialog.alert({
            title: '错误',
            message: '您请求的工单不存在'
          }).then(() => {
            this.backUrl()
          })
        }

        this.data = response.data
        this.eqName = this.data.eq.name
        this.eqId = this.data.eq_id
        this.eqList = response.data.other_eq
        if (this.data.status === -1) {
          this.showOrderEndTime = this.data.end_time
        }
        this.statusOut = this.selectName(this.pmStatus, response.data.status)
      }).finally(() => {
        this.pmLoadingHidden = true
        this.pmLoading = false
        this.detailLoading = false
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onDetailRefresh() {
      this.detailLoading = true
      this.showToast = true
      this.index()
    },
    submitReading() {
      const postData = {
        data: Object.assign({},this.eqList),
        id: this.$route.params.id,
        eq: this.data.equipment_id,
        start: this.data.qrcode_time,
        complete: this.data.submit_complete
      }
      API.submitReading(postData).then((response) => {
        if (response.data.error_code === 1) {
          Notify({
            type: 'success',
            message: '恭喜你，提交成功'
          })
          this.backUrl()
        } else if (response.data.error_code === 11) {
          Notify({
            type: 'success',
            message: '参数不正常，已自动生成报修单'
          })
          this.backUrl()
        } else {
          Notify({
            type: 'danger',
            message: response.data.message
          })
        }
      })
    },
    getConfig() {
      const self = this
      let url = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.localStorage.getItem('scanUrl');

      WxApi.wxJSTicket({ url: url }).then((res) => {
        const data = res.data;
        wx.config({
          beta: true,
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名
          jsApiList: ['chooseImage','uploadImage','getLocalImgData','scanQRCode','checkJsApi'] // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          console.log("成功")
          self.finishWx = true
        })
        wx.error(function (err) {
          console.log(err)
        })
      })
    },
    checkJsApi(api) {
      //检测api是否可用
      return new Promise((rel, err) => {
        wx.checkJsApi({
          jsApiList: [api], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            if (res.checkResult && res.checkResult.chooseImage == false) {
              this.$toast("微信版本较低，请升级后重试！")
              err("版本低")
            } else {
              rel(true)
            }
          }
        })
      })
    },
    takePhoto(id, index, eqId) {
      const self = this
      this.checkJsApi("scanQRCode").then((rel) => {
        if (rel) {
          wx.chooseImage({
            count: 1, // 默认9
            sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
            sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
            success: function (res) {
              const localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
              if(localIds.length>0){
                self.wxUploadPic(localIds, id, index, eqId)
              }
            }
          })
        }
      })
    },
    wxUploadPic(localIds, id, index, eqId){
      const self = this
      this.checkJsApi("scanQRCode").then((rel) => {
        if (rel) {
          wx.getLocalImgData({
            localId: localIds[0].toString(),
            success: function (res) {
              const localData = res.localData
              let imageBase64 = ''
              if (localData.indexOf('data:image') == 0) {
                //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
                imageBase64 = localData
              } else {
                //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
                //此时一个正常的base64图片路径就完美生成赋值到img的src中了
                imageBase64 = 'data:image/jpeg;base64,' + localData.replace(/\n/g, '')
              }
              if (imageBase64) {
                let param = {
                  base64data: imageBase64,
                  id
                }
                API.photo(param).then(res => {
                  self.eqList[eqId][index].v.photo = res.data.img_path
                })
              } else {
                Toast("选择图片出了点问题，请稍后再试下吧");
              }
            }
          })
        }
      })
    },
    qrCodeTime() {
      if (!this.data['qrcode_time'] && this.data['status'] === 1) {
        API.SelectEqTime({
          'equipment_id': this.data.equipment_id,
          'workorder_id': this.$route.params.id
        }).then(res => {
          console.log(res)
        })
      }
    },
    showPartsPicker() {
      this.showParts = true
      SELECT.partsList(this.partsQuery).then(res => {
        this.partsList = res.data.data
      })
    },
    searchParts() {
      this.partsQuery.add_field = 'coding'
      SELECT.partsList(this.partsQuery).then(res => {
        this.partsList = res.data.data
      })
    },
    hideParts(val) {
      this.showParts = false
      this.order.parts_id = val.id
      this.order.partsShow = val.name

      this.applyParts.number = val.number
      this.applyParts.coding = val.coding
      this.applyParts.store = val.store.name
      this.applyParts.spec = val.spec
    },
    appendPartsHandle() {
      const parts_data = {
        parts_id: this.order.parts_id,
        notice: this.order.notice,
        number: this.needPartsNumber,
        workorder_id: this.$route.params.id,
        workorder_type: 2
      }
      API.appendParts(parts_data).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '新增成功' })
          this.index()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    removePartsHandle(id, already) {
      if (already === 3) {
        Notify({
          type: 'danger',
          message: '已经领取到手了，不能直接删除'
        })
        return false
      }
      const parts_data = {
        id: id,
        type: 'pm'
      }
      API.removeParts(parts_data).then((res) => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '删除成功' })
          this.index()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    getParts() {
      const needData = {
        id: this.data.id,
        type: 'pm'
      }
      API.needParts(needData).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '请求备件，请速去库房领件！' })
          this.backUrl()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    handOutHandle() {
      API.handOut({ id: this.data.id, end_time: this.showOrderEndTime }).then(res => {
        if (res.data.error_code === 1) {
          Notify({
            type: 'success',
            message: '恭喜你，派发成功'
          })
          this.backUrl()
        } else {
          Notify({
            type: 'danger',
            message: res.data.message
          })
        }
      })
    },
    monitorHandle(status) {
      const reviewData = {
        'id': this.data.id,
        status
      }
      API.monitor(reviewData).then((res) => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '提交成功' })
          this.backUrl()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    engineerHandle(status) {
      const reviewData = {
        'id': this.data.id,
        status
      }
      API.engineer(reviewData).then((res) => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '提交成功' })
          this.backUrl()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    saveHandle() {
      API.update(this.data).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '更新成功' })
          this.index()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    hideStart() {
      const dateTime = this.dateTimeFormat(Date.parse(this.orderTime.start))
      this.showStartTime = dateTime
      this.showStart = false
    },
    hideComplete() {
      const dateTime = this.dateTimeFormat(Date.parse(this.orderTime.end))
      this.showEndTime = dateTime
      this.showComplete = false
    },
    hideOrderEnd() {
      const dateTime = this.dateTimeFormat(Date.parse(this.orderTime.order_end))
      this.showOrderEndTime = dateTime
      this.showEnd = false
    },
    appendTimeHandle() {
      const postData = {
        start_time: this.showStartTime,
        end_time: this.showEndTime,
      }
      postData.workorder_id = this.data.id
      postData.equipment_id = this.data.equipment_id
      postData.workorder_type = 2
      API.appendTime(postData).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '添加成功' })
          this.index()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    removeTimeHandle(id) {
      API.removeTime({ id: id, workorder_type: 2 }).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '删除成功' })
          this.index()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    showAdminPicker() {
      this.showAdmin = true
      SELECT.adminList(this.partsQuery).then(res => {
        this.adminList = res.data.data
      })
    },
    searchAdmin() {
      this.adminQuery.add_field = 'coding'
      SELECT.adminList(this.adminQuery).then(res => {
        this.adminList = res.data.data
      })
    },
    hideAdmin(val) {
      this.showAdmin = false
      this.admins.admin_id = val.id
      this.admins.adminShow = val.name
    },
    appendMemberHandle() {
      const parts_data = {
        admin_id: this.admins.admin_id,
        workorder_id: this.data.id,
        workorder_type: 2
      }
      API.appendMember(parts_data).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '新增成功' })
          this.index()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    removeMemberHandle(id) {
      API.removeMember({ id: id, workorder_type: 2 }).then(res => {
        if (res.data.error_code === 1) {
          Notify({ type: 'success', message: '删除成功' })
          this.index()
        } else {
          Notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    clickTabs(name) {
      if (name === 1) {
        this.noticeOption.text = '工单非完成状态，随时都可以改变执行人，支持模糊搜索，向左滑动即可删除'
      } else if (name === 2) {
        this.noticeOption.text = '工单只有在维修中才可以添加时间段，请认真填写，不可变更！'
      } else if (name === 3) {
        this.noticeOption.text = '工单派发和待审批之前都可以添加并请求备件，没领取（非绿颜色）的备件向左滑动即可删除'
      } else {
        this.noticeOption.text = ''
      }
    }
  }
}
</script>

<style scoped>
.order-detail{
  box-sizing: border-box;
  text-align: left;
  padding: 10px 10px 80px;
}
.order-detail .innerBox{
  border-bottom: 1px dashed #999;
  margin-bottom: 10px;
}
.order-detail .innerBox .innerReading{
  margin-bottom: 10px;
  border: 1px solid #afadad;
}
.van-field__control{
  border: 1px solid !important;
  padding-left: 10px;
}
.mb20{
  margin-bottom: 20px;
}
.mr10{
  margin-right: 10px;
}
.font-black{
  color: #000000;
}
.buttonBox{
  margin-top: 10px;
}
.pmPartsList .fl{
  width: 90%;
}
.pmPartsList .fr{
  width: 10%;
}
.pmTimeTitle span{
  width: 50%;
  display: block;
}
</style>
